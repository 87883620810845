body {
  background: #111;
  color: #666;
}

.season-selector-container {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid #333;
}

.season-selector-container input {
  font-size: 1.5rem;
  width: 600px;
}

input {
  text-align: center;
  background: #222;
  color: #666;
  border: 1px solid #333;
  padding: 10px 15px;
}

input:active,
input:focus {
  border: 1px solid #444;
  color: #777;
}

.basis-row {
  padding: 5px 25px;
}

.basis-row:nth-of-type(odd){
  background: rgba(0, 0, 0, 0.1);
}

.basis-row.excluded {
  color: #333 !important;
}

.row-container h3 {
  padding: 5px 25px;
}

.row-container h3 span {
  margin-right: 20px;
  display: inline-block;
}

.row-container h3 span:not(.clickable) {
  text-decoration: underline;
  color: #888;
}

.row-container h3 span.clickable {
  cursor: pointer;
}

.basis-row span {
  margin-right: 5px;
}

.event {
  border-right: 1px solid #444;
  width: 25%;
  float: left;
}

.event:last-of-type {
  border-right: 0;
}

.event-name {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.event-name input {
  margin: 2em 0 2em 44px;
}

.no-event-content {
  text-align: center;
  font-style: italic;
}

.fetcher {
  cursor: pointer;
  height: 24px;
  width: 24px;
  margin: 2em 10px;
}

.ruthless {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  border-radius: 24px;
  text-align: center;
  font-size: 11px;
  padding-bottom: 1px;

  color: gray;
  border: 3px solid gray;
}

.ruthless.active {
  color: #a21313;
  border:3px solid darkred;
  background: #510101;
}

.fetcher img {
  cursor: pointer;
  width: 100%;
  height: 100%;
}

.button-container {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid #333;
}

.button-container button {
  outline: none;
  border: 0;
  padding: 15px;
  margin: 10px;
  cursor: pointer;
}

.exclude {
  color: #eee;
  background: #5f5099;
}

.remove {
  color: #eee;
  background: #8b2d35;
}

.re-index {
  color: #eee;
  background: #3d7480;
}

.trim {
  color: #eee;
  background: #755b4f;
}

.send {
  color: #eee;
  background: #366447;
}